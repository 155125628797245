import React from 'react';
import styled from 'styled-components';
import { colors } from '../consts/style';

const NotFound = styled.div`
  height: calc(100vh - 340px);
  display: flex;
  justify-content: center;
  align-items: center;
  h1 {
    color: ${colors.gold};
  }
`;

const NotFoundPage = () => (
  <NotFound>
    <h1>404</h1>
  </NotFound>
);

export default NotFoundPage;
